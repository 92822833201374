import React, {useState} from "react";

export interface SendTestButtonProps {
    channel: "SMS" | "Email" | "Push";
    device?: string | null;
    sendTestNotification: (
        channel: "SMS" | "Email" | "Push",
        device?: string | null
    ) => Promise<void>;
    cooldown?: number;
    className?: string;
    children?: React.ReactNode;
}

const SendTestButton: React.FC<SendTestButtonProps> = ({
    channel,
    device = null,
    sendTestNotification,
    cooldown = 10000,
    className,
    children,
}) => {
    const [isSending, setIsSending] = useState(false);



    const handleSendTest = async () => {
        setIsSending(true);
        try {
            await sendTestNotification(channel, device);
        } catch (error) {
            console.error(error);
        } finally {
            setTimeout(() => {
                setIsSending(false);
            }, cooldown);
        }
    };

    return (
        <button disabled={isSending} className={className} onClick={handleSendTest}>
            {isSending ? "Sending..." : children || "Send Test"}
        </button>
    );
};

export default SendTestButton;
