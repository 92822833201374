import WatsonApi from "../../backends/WatsonApi";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import NotificationManager from "../../components/notifications/NotificationManager";
import {NotificationItem} from "./NotificationItem";
import styles from "../styles/userSettingsPageStyles.module.scss";
import ToggleSwitch from "../../components/admin/sidebar/items/ToggleSwitch";
import {formatPhoneNumber} from "../../components/modals/PersonModal/TeacherBio";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {observer} from "mobx-react";
import PhoneNumber from "../../components/utilities/PhoneNumber";
import InlineTextEditor from "../../components/utilities/InlineTextEditor";
import FontAwesome from "../../components/utilities/FontAwesome";
import useSWR from "swr";
import {blockObjectFactory} from "../../components/blocks/SchoolBlocks/blockUtils";
import SchoolBlocksLoadingIndicator from "../../components/utilities/SchoolBlocksLoadingIndicator";
import {getDistrict} from "../../utils/SchoolBlocksUtilities";
import SendTestButton from "./SendTestButton";
import OrgSubscriptionsTable from "./OrgSubscriptionsTable";
import {IUserWithSubscriptions} from "./UserSettingsPage";
import ClickableLink from "../../components/utilities/ClickableLink";

export interface IPersonBlockWithPushSubscriptions extends Omit<IPersonBlock, "blockModel"> {
    blockModel: IUserWithSubscriptions
}

const { UAParser } = require("ua-parser-js");

export function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== "granted") {
            throw new Error(
                "Permission not granted to enable push notifications! Please try again."
            );
        }
    });
}

export function isPushSupported() {
    return (
        "serviceWorker" in navigator &&
        "PushManager" in window &&
        "Notification" in window
    );
}

export function getDeviceStringFromUserAgent(userAgent) {
    const parsed = new UAParser(userAgent).getResult();
    let deviceModel = parsed.device.model;
    if (!deviceModel) {
        // ua-parser doesn't handle desktop devices, this is the best guess
        // see https://github.com/faisalman/ua-parser-js/issues/182
        deviceModel = `${parsed.os.name} Desktop`;
    }
    return `${deviceModel || "Unknown Device"}/${parsed.browser.name}`;
}

export const NotificationSettings = observer((props) => {
    const { organizationStore, interfaceStore, userStore } =
        useContext(StoreContext);

    const [pushSupported, setPushSupported] = useState(false);
    const [viewSubmitButton, setViewSubmitButton] = useState(false);
    const [orgList, setOrgList] = useState<OrganizationTypeOrganization[]>([]);
    const deviceType = new UAParser(interfaceStore.userAgent).getDevice().type || "desktop";
    const [testNotificationIds, setTestNotificationIds] = useState({
        Email: "",
        SMS: "",
        Push: "",
    });
    const helpDocUrl = "https://support.schoolblocks.com/article/99-notification-troubleshooting-guide"

    const { data, isLoading } = useSWR<any, IPersonBlockWithPushSubscriptions>(
        "fetch-user-obj-with-subscriptions",
        async () => {
            try {
                const client = await WatsonApi();
                const response =
                    await client.apis.organizations.organizations_users_read({
                        organization_pk: organizationStore.currentOrganization.id,
                        id: userStore.id,
                        expand: ["push_subscriptions.organization"],
                    });
                return blockObjectFactory("person", {
                    blockModel: JSON.parse(response.data),
                } as IPersonBlock);
            } catch (e) {
                console.error(e);
                return {
                    message: "Error fetching User object with push subscriptions.",
                };
            }
        },
        {
            revalidateOnMount: true,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    );

    const getAllOrgs = async () => {
        try {
            const district = getDistrict(organizationStore.currentOrganization);
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_list({
                organization_pk: organizationStore.organization.id,
                path__descendant_or_eq: district.path,
                ordering: ["path", "-title"],
                deleted: "false",
                type__in: ["school", "district"],
                expand: ["is_following"],
                page_size: 1000,
            });
            setOrgList(response.body.data);
        } catch (error) {
            console.error(error);
        }
    };

    const emailEnabled = !!(
        data?.blockModel.notifications?.email && data.blockModel.email
    );
    const phoneEnabled = !!(
        data?.blockModel.notifications?.phone && data.blockModel.mobile_phone
    );
    const pushEnabled = !!data?.blockModel.notifications?.push;
    const pushSubscriptions = data?.blockModel.push_subscriptions;

    useAsyncEffect(async () => {
        if (pushSubscriptions?.length === 0 && pushEnabled) {
            // Turn off push notifications if there are no subscriptions
            await updateNotificationSetting("push");
        } else if (!pushEnabled && pushSubscriptions?.length) {
            // Turn on push notifications if there are active subscriptions
            await updateNotificationSetting("push");
        }
    }, [pushSubscriptions?.length, pushEnabled]);

    useEffect(() => {
        if (
            "serviceWorker" in navigator &&
            "PushManager" in window &&
            "Notification" in window
        ) {
            void navigator.serviceWorker.ready.then((serviceWorker) => {
                if (serviceWorker.active) {
                    setPushSupported(true);
                }
            });
        }
        void getAllOrgs();
    }, []);

    const updateNotificationSetting = async (
        field: "push" | "phone" | "email"
    ) => {
        try {
            const stateMapping = {
                phone: phoneEnabled,
                push: pushEnabled,
                email: emailEnabled,
            };

            const client = await WatsonApi();
            const response =
                await client.apis.organizations.organizations_users_partial_update({
                    organization_pk: organizationStore.currentOrganization.id,
                    id: data?.blockModel.id,
                    data: {
                        notifications: {
                            phone: phoneEnabled,
                            email: emailEnabled,
                            push: pushEnabled,
                            [field]: !stateMapping[field],
                        },
                    },
                });
            const userObj = JSON.parse(response.data);
            data &&
            data.setAttributes({
                blockModel: {
                    ...data.blockModel,
                    notifications: userObj.notifications,
                },
            });
            NotificationManager.success("Settings have been updated!");
        } catch (e) {
            throw new Error("Something went wrong! Please try again.");
        }
    };

    async function handleSubmit() {
        let result = {
            status: undefined,
        };
        const client = await WatsonApi();
        try {
            result =
                await client.apis.organizations.organizations_users_partial_update({
                    organization_pk: organizationStore.currentOrganization.id,
                    id: data?.blockModel.id,
                    data: {
                        mobile_phone: data?.blockModel.mobile_phone,
                        email: data?.blockModel.email,
                    },
                });
            if (result.status === 200) {
                setViewSubmitButton(false);
                NotificationManager.success(
                    "Notification Settings updated successfully."
                );
            }
        } catch (e) {
            console.error(`ERROR: ${e.toString()}`);
            NotificationManager.error("Error updating Notification Settings.");
        }
    }

    async function createPushSubscription() {
        try {
            const registration = await navigator.serviceWorker.ready;
            await askPermission();
            const pushSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                    "BFcZZ6FiITumPysCi_t9B1TANBHATH7r0Y4bKVRomTVRo14k5eNU0L9LhgZ3ogL7xPkGYh-RqtFN14RlnU3rDWg",
            });
            const client = await WatsonApi();
            const push_subscription_response =
                await client.apis.users.users_push_subscription_create({
                    user_pk: userStore.id,
                    data: {
                        organization_id: organizationStore.organization.id,
                        user_id: userStore.id,
                        subscription_object: pushSubscription,
                        user_agent: interfaceStore.userAgent,
                        device_type: deviceType,

                    },
                });
            const newSubscription = JSON.parse(push_subscription_response.data);
            data &&
            data.setAttributes({
                blockModel: {
                    ...data.blockModel,
                    push_subscriptions: [
                        ...data.blockModel.push_subscriptions.filter(
                            (sub) => sub.id !== newSubscription.id
                        ),
                        {
                            ...newSubscription,
                            organization: organizationStore.organization,
                        },
                    ],
                },
            });
        } catch (subscriptionError) {
            throw new Error(subscriptionError.message);
        }
    }

    async function deletePushSubscription(push_subscription) {
        const client = await WatsonApi();
        await client.apis.users.users_push_subscription_delete({
            user_pk: userStore.id,
            id: push_subscription.id,
        });
        data &&
        data.setAttributes({
            blockModel: {
                ...data.blockModel,
                push_subscriptions: data.blockModel.push_subscriptions.filter(
                    (sub) => sub.id !== push_subscription.id
                ),
            },
        });
    }

    async function sendTestNotification(channel, device) {
        const userId = userStore.id;
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_users_send_test_notification({
                organization_pk: organizationStore.organization.id,
                id: userId,
                channel: channel.toLowerCase(),
                device: device,
            });
            NotificationManager.success(
                "Test notification sent!"
            );
            setTestNotificationIds({
                ...testNotificationIds,
                [channel]: response.body.test_notification_id,
            });
        } catch (error) {
            console.error(error);
        }
    }


    const subscriptionForCurrentDeviceAndOrganization = pushSubscriptions?.find(
        (sub) => {
            return (
                getDeviceStringFromUserAgent(sub.user_agent) ===
                getDeviceStringFromUserAgent(interfaceStore.userAgent) &&
                sub.organization_id === organizationStore.organization.id
            );
        }
    );

    const handleToggle = async (orgId) => {
        try {
            const client = await WatsonApi();
            await client.apis.toggle_follow
                .toggle_follow_toggle_follow_organization({
                    itemname: orgId,
                });

            // Update the organization in the state immutably
            setOrgList((prevOrgList) =>
                prevOrgList.map((org) =>
                    org.id === orgId
                        ? { ...org, is_following: !org.is_following }
                        : org
                )
            );
            if (orgId === organizationStore.currentOrganization.id) {
                const newValue = !userStore.follower;
                userStore.setPermissions({
                    follower: newValue,
                })
            }


            const toggledOrg = orgList.find((org) => org.id === orgId);
            if (toggledOrg) {
                NotificationManager.success(
                    toggledOrg?.is_following
                        ? `You are no longer subscribed to ${toggledOrg.title}.`
                        : `You are now subscribed to ${toggledOrg.title}.`
                );
            }

        } catch (e) {
            console.error(e);
            NotificationManager.error(JSON.parse(e.response.text));
        }
    };

    if (isLoading) {
        return (
            <div className={styles.notificationSettings}>
                <SchoolBlocksLoadingIndicator fill={"$dark-gray"} size={70} />
            </div>
        );
    }

    return (
        <div id="my-notification-settings-tab">
            <h3 className={styles.heading}>Notification Settings</h3>
            <p>
                Please select which channels you want to receive notifications through.
                All enabled channels will be used when an admin sends a notification.
            </p>
            <p>
                If you think you should be receiving a notification, but are not, please check out our
                <ClickableLink target={"_blank"} href={helpDocUrl}> help document.</ClickableLink>
            </p>

            {/* Table layout with horizontal row dividers only */}
            <table className={styles.settingsTable}>
                <thead>
                    <tr>
                        <th>Notification</th>
                        <th>Enabled</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Phone (Text Messages) Row */}
                    <tr>
                        <td>
                            <div className={styles.notificationInfo}>
                                <FontAwesome
                                ariaHidden={true}
                                prefix={"fas"}
                                name="fa-comment"
                                className={styles.itemIcon}
                            />
                                <div className={styles.notificationDetails}>
                                    <InlineTextEditor
                                    key="mobile_phone"
                                    placeholder={"Enter phone..."}
                                    wrapperClassName={styles.editable}
                                    canEdit={true}
                                    text={
                                        data && data.blockModel.mobile_phone
                                            ? formatPhoneNumber(data.blockModel.mobile_phone)
                                            : ""
                                    }
                                    handleTextChange={(newValue) => {
                                        const currentValue =
                                            data &&
                                            data.blockModel.mobile_phone &&
                                            formatPhoneNumber(data.blockModel.mobile_phone);
                                        if (newValue !== currentValue) {
                                            setViewSubmitButton(true);
                                        }
                                        const phoneNumber = Number(newValue?.replace(/\D/g, ""));
                                        data &&
                                        data.setAttributes({
                                            blockModel: {
                                                ...data.blockModel,
                                                mobile_phone: phoneNumber,
                                            },
                                        });
                                    }}
                                    isSimple={true}
                                    showHoverUI={true}
                                >
                                        {data && data.blockModel.mobile_phone ? (
                                            <PhoneNumber
                                            number={formatPhoneNumber(data.blockModel.mobile_phone)}
                                        />
                                    ) : (
                                        <span>Enter Phone</span>
                                    )}
                                    </InlineTextEditor>
                                    <small>Text Messages</small>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ToggleSwitch
                            onClick={() => updateNotificationSetting("phone")}
                            on={phoneEnabled}
                            enabled={!!(data && data.blockModel.mobile_phone)}
                            title={
                                data && data.blockModel.mobile_phone
                                    ? "Toggle text messages"
                                    : "Add a phone number to enable"
                            }
                        />
                        </td>
                        {phoneEnabled && (
                            <td>
                                <SendTestButton
                                    channel="SMS"
                                    sendTestNotification={sendTestNotification}
                                    className={styles.sendTestNotification}
                                />
                            </td>
                    )}
                    </tr>

                    {/* Conditionally show Update Phone Number button */}
                    {viewSubmitButton && (
                        <tr>
                            <td colSpan={3} style={{ textAlign: "right" }}>
                                <button
                                className="btn sb-organization-color-element-bg"
                                onClick={handleSubmit}
                            >
                                    Update Phone Number
                                </button>
                            </td>
                        </tr>
                )}

                    {/* Email Notifications Row */}
                    <tr>
                        <td>
                            <div className={styles.notificationInfo}>
                                <FontAwesome
                                ariaHidden={true}
                                prefix={"fas"}
                                name="fa-at"
                                className={styles.itemIcon}
                            />
                                <div className={styles.notificationDetails}>
                                    <InlineTextEditor
                                    key="email"
                                    placeholder={"Enter an email..."}
                                    wrapperClassName={styles.editable}
                                    canEdit={false}
                                    text={data && data.blockModel.email ? data.blockModel.email : ""}
                                    handleTextChange={(value) => {
                                        data &&
                                        data.setAttributes({
                                            blockModel: {
                                                ...data.blockModel,
                                                email: value,
                                            },
                                        });
                                    }}
                                    isSimple={true}
                                    showHoverUI={true}
                                >
                                        {data && data.blockModel.email ? (
                                        data.blockModel.email
                                    ) : (
                                        <span>Enter email...</span>
                                    )}
                                    </InlineTextEditor>
                                    <small>Email Notifications</small>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ToggleSwitch
                            onClick={() => updateNotificationSetting("email")}
                            on={emailEnabled}
                            enabled={!!(data && data.blockModel.email)}
                            title={
                                data && data.blockModel.email
                                    ? "Toggle email messages"
                                    : "Add an email to enable"
                            }
                        />
                        </td>
                        {emailEnabled && (
                            <td>
                                <SendTestButton
                                    channel="Email"
                                    sendTestNotification={sendTestNotification}
                                    className={styles.sendTestNotification}
                                />
                            </td>
                    )}
                    </tr>

                    {/* Push Notifications (for current device) Row */}
                    <tr>
                        <td>
                            <div className={styles.notificationInfo}>
                                <FontAwesome
                                ariaHidden={true}
                                prefix={"fas"}
                                name={
                                    interfaceStore.isPWA ? "fa-mobile-alt" : "fa-laptop"
                                }
                            />
                                <div className={styles.notificationDetails}>
                                    <p>{organizationStore.organization.title}</p>
                                    <small>
                                        {getDeviceStringFromUserAgent(interfaceStore.userAgent)}{" "}
                                        Notifications
                                    </small>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ToggleSwitch
                            enabled={pushSupported}
                            title={
                                pushSupported
                                    ? "Enable app notifications on current device"
                                    : "Push notifications are not supported on this device or browser"
                            }
                            onClick={async () => {
                                if (subscriptionForCurrentDeviceAndOrganization) {
                                    await deletePushSubscription(
                                        subscriptionForCurrentDeviceAndOrganization
                                    );
                                } else {
                                    try {
                                        await createPushSubscription();
                                    } catch (e) {
                                        NotificationManager.error(e.message);
                                    }
                                }
                            }}
                            on={!!subscriptionForCurrentDeviceAndOrganization}
                        />
                        </td>
                        {!!subscriptionForCurrentDeviceAndOrganization && (
                            <td>
                                <SendTestButton
                                    channel="Push"
                                    device={interfaceStore.userAgent}
                                    sendTestNotification={sendTestNotification}
                                    className={styles.sendTestNotification}
                                />
                            </td>
                    )}
                    </tr>

                    {/* Extra push subscriptions (if any) */}
                    {pushSubscriptions
                    ?.filter((sub) =>
                        subscriptionForCurrentDeviceAndOrganization
                            ? sub.id !== subscriptionForCurrentDeviceAndOrganization.id
                            : true
                    )
                    .map((sub) => (
                        <NotificationItem
                            key={sub.id}
                            push_subscription={sub}
                            deletePushSubscription={deletePushSubscription}
                            sendTestNotification={sendTestNotification}
                        />
                    ))}
                    {
                        pushSubscriptions &&

                        !pushSubscriptions.some(sub => sub.device_type === "mobile") && (
                            <tr>
                                <td>
                                    <div className={styles.notificationInfo}>
                                        <FontAwesome
                                            ariaHidden={true}
                                            prefix="fas"
                                            name="fa-mobile-alt"
                                            className={styles.itemIcon}
                                        />
                                        <div className={styles.notificationDetails}>
                                            <p>Mobile App</p>
                                            <small>Visit the website on mobile to download the app!</small>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        )
                    }

                </tbody>
            </table>

            <OrgSubscriptionsTable orgList={orgList} onToggleOrg={handleToggle} setActiveTab={props.setCurrentView}/>


        </div>
    );
});

export default NotificationSettings;
