import React from "react";
import ToggleSwitch from "../../components/admin/sidebar/items/ToggleSwitch";
import ClickableLink from "../../components/utilities/ClickableLink";
import styles from "../styles/userSettingsPageStyles.module.scss";

interface OrgSubscriptionsTableProps {
    orgList: any[];
    onToggleOrg: (orgId: number | string) => Promise<void>;
    setActiveTab: (tab: string) => void;
}

export default function OrgSubscriptionsTable({
    orgList,
    onToggleOrg,
    setActiveTab,
}: OrgSubscriptionsTableProps) {
    return (
        <div style={{ marginTop: "1rem" }}>
            <strong>
                Choose which District and Schools you receive notifications from
            </strong>
            <p>
                You can follow other pages throughout the site to receive notifications
                from those administrators.
                <br />
                Manage these in the
                <ClickableLink
        onClick={() => setActiveTab("following")}
        >
                    &nbsp;
                    Subscriptions
                    &nbsp;
                </ClickableLink>
                tab.
            </p>
            <table className={styles.followTable}>
                <tbody>
                    {orgList.map((org) => (
                        <tr key={org.id} className={styles.row}>
                            <td>
                                <span className={styles.title}>{org.title}</span>
                            </td>
                            <td>
                                <ToggleSwitch
                    title={
                    org.is_following
                        ? "Unsubscribe from this organization."
                        : "Subscribe to this organization."
                }
                    onClick={() => onToggleOrg(org.id)}
                    on={!!org.is_following}
                    className={styles.toggle}
    />
                            </td>
                        </tr>
))}
                </tbody>
            </table>
        </div>
    );
}
