import styles from "../styles/userSettingsPageStyles.module.scss";
import React, {useState} from "react";
import ToggleSwitch from "../../components/admin/sidebar/items/ToggleSwitch";
import {getDeviceStringFromUserAgent} from "./NotificationSettings";
import FontAwesome from "../../components/utilities/FontAwesome";
import SendTestButton from "./SendTestButton";

export function NotificationItem(props: {
    push_subscription: PushSubscription,
    deletePushSubscription: (sub: PushSubscription) => void,
    sendTestNotification: (channel: "SMS" | "Push" | "Email", device?: string | null) => Promise<void>,
}) {
    const [on, setOn] = useState(true);

    // Determine the icon based on the subscription's device type.
    const iconName = props.push_subscription.device_type === "mobile" ? "fa-mobile-alt" : "fa-laptop";
    const iconClass = props.push_subscription.device_type === "mobile" ? styles.itemIcon : styles.laptopIcon;

    // Simulate disabling the subscription (and then delete it) when toggled.
    function handleDelete() {
        setOn(false);
        setTimeout(() => props.deletePushSubscription(props.push_subscription), 500);
    }

    return (
        <tr>
            {/* Column 1: Notification Info */}
            <td>
                <div className={styles.notificationInfo}>
                    <FontAwesome
                        ariaHidden={true}
                        prefix={"fas"}
                        name={iconName}
                        className={iconClass}
                    />
                    <div className={styles.notificationDetails}>
                        <p>{props.push_subscription.organization.title}</p>
                        <small>
                            {getDeviceStringFromUserAgent(props.push_subscription.user_agent)} Notifications
                        </small>
                    </div>
                </div>
            </td>

            {/* Column 2: Toggle Switch (used here as a delete button) */}
            <td>
                <ToggleSwitch
                    enabled={on}
                    title={on ? "Enabled" : "Disabled"}
                    onClick={handleDelete}
                    on={on}
                />
            </td>

            {/* Column 3: Send Test Button */}
            <td>
                <SendTestButton
                    channel="Push"
                    device={props.push_subscription.user_agent}
                    sendTestNotification={props.sendTestNotification}
                    className={styles.sendTestNotification}
                />
            </td>
        </tr>
    );
}
